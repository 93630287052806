<template>
  <div>
    <InviteEmployeeModal @invited="init" />
    <EditEmployeeModal ref="editEmployeeModal" @updated="employeeUpdated" />
    <FadeTransition>
      <div v-if="initialized">
        <InviteTeammateCard v-if="invites.length === 0 && employees.length < 2" />
        <div v-if="invites.length">
          <h3>Pending Invitations</h3>
          <hr class="mt-4 mb-5" />
          <div class="row">
            <div v-for="invite in invites" :key="invite._id" class="col-12 col-md-6">
              <EmployeeInviteCard :invite="invite" :employee="invite.employee" @deleted="init" />
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col">
            <h3 class="mb-0 mt-3">Joined</h3>
          </div>
        </div>
        <hr class="mt-4 mb-4" />
        <div class="card form-control mb-4 py-1 justify-content-center" style="height: 46px">
          <FuzzySearch
            :arrayData="employees"
            :keys="['firstName', 'lastName']"
            @filteredResults="applyResults"
          />
        </div>
        <div class="row">
          <div
            v-for="employee in employeeResults"
            :key="employee._id + employee.locations.length + refreshKey"
            class="col-12 col-md-6"
          >
            <TeammateCard
              :employee="employee"
              @edit="editEmployee(employee)"
              @delete="deleteEmployee(employee)"
            />
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center h-100" v-else>
        <b-spinner variant="primary" />
      </div>
    </FadeTransition>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')
import CompanySettingsBase from '../CompanySettingsBase'
import InviteTeammateCard from './InviteTeammateCard'
import TeammateCard from './TeammateCard'
import EmployeeInviteCard from './EmployeeInviteCard'
import InviteEmployeeModal from '@/components/Modals/InviteEmployeeModal'
import EditEmployeeModal from '@/components/Modals/EditEmployeeModal'
import FadeTransition from '@/components/Transitions/FadeTransition'
import CompanyService from '@/services/CompanyService'
import UserService from '@/services/UserService'
import FuzzySearch from '@/components/Modules/Filters/ArrayFuzzySearch'

export default {
  name: 'CompanySettingsTeam',
  props: ['inviteEmployeePopup'],
  extends: CompanySettingsBase,
  components: {
    InviteEmployeeModal,
    EditEmployeeModal,
    EmployeeInviteCard,
    InviteTeammateCard,
    TeammateCard,
    FadeTransition,
    FuzzySearch,
  },
  computed: {
    ...UserModule.mapState(['onlineUser']),
    ...CompanyModule.mapState(['activeCompany']),
  },
  data: () => ({
    initialized: false,
    employees: [],
    invites: [],
    employeeResults: null,
    refreshKey: 0,
  }),
  async mounted() {
    await this.init()
    this.employeeResults = this.employees
    this.initialized = true
  },
  methods: {
    async init() {
      const response = await CompanyService.getTeam({
        userId: this.onlineUser._id,
        companyId: this.activeCompany._id,
      })

      const { employees, invites } = response.body

      this.employees = [...employees]
      this.invites = [...invites].filter((invite) => invite.status === 'pending')
    },
    async employeeUpdated() {
      await this.init()
      this.employeeResults = this.employees
      this.refreshKey++
    },
    editEmployee(employee) {
      this.$refs.editEmployeeModal.setEmployee(employee)
      this.$root.$emit('bv::show::modal', 'editEmployeeModal')
    },
    async deleteEmployee(employee) {
      const deletedEmployeeId = employee._id
      await UserService.deleteUserFromCompany(
        deletedEmployeeId,
        this.activeCompany._id,
        this.onlineUser._id
      )
      this.employees = this.employees.filter((employee) => employee._id !== deletedEmployeeId)
      this.employeeResults = [...this.employees]
      this.$notify({
        title: "They're gone!",
        text: `${employee.firstName} has been removed.`,
      })
    },
    applyResults(employeeResults) {
      this.employeeResults = employeeResults
    },
  },
}
</script>

<style scoped lang="scss"></style>
