<template>
  <div title="Promo">
    <!-- Add Promo Modal -->
    <CreatePromoModal v-if="includeModal" @added="addedPromo" ref="createPromoModal" />

    <!-- Button to add if no promos exist -->
    <a
      class="btn btn-white mb-0 d-flex align-items-center"
      :class="large ? 'btn-lg' : 'btn-sm'"
      href="javascript:;"
      v-if="!promos.length && selectIsPermitted('promosReadAccess')"
      @click="addPromo"
    >
      <i class="fe fe-plus mr-1" :class="large && 'icon-large'"></i> Offer
    </a>

    <!-- Promos Dropdown -->
    <b-dropdown
      ref="promoDropdown"
      :variant="selectedPromo ? 'primary' : 'link'"
      :size="large ? 'lg' : 'sm'"
      v-if="promos.length"
      class="hide-caret"
      id="promo-dropdown"
    >
      <template slot="button-content">
        <span
          class="text-capitalize d-flex align-items-center"
          :class="{ 'promo-span': large, 'p-1': selectedPromo }"
        >
          <GiftIcon
            size="1.56x"
            :class="{ 'action-icon': !selectedPromo }"
            :style="{ color: selectedPromo ? 'white' : '#95aac9' }"
          ></GiftIcon>
          <span class="mb--1 ml-1">
            {{
              selectedPromo
                ? selectedPromo.nickname || selectedPromo.title
                : (large ? 'No Offer' : '') | truncate(20)
            }}
          </span>
        </span>
      </template>
      <b-dropdown-item
        v-for="promo in filteredPromos"
        :key="promo._id"
        class="dropdown-item text-capitalize"
        :class="large && 'h4'"
        @click="setPromo(promo)"
        >{{ promo.nickname || promo.title | truncate(50) }}</b-dropdown-item
      >
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="addPromo" v-if="selectIsPermitted('promosReadAccess')"
        >Create Offer</b-dropdown-item
      >
      <div v-if="selectedPromo" class="dropdown-divider"></div>
      <b-dropdown-item class="text-warning" @click="reset()" v-if="selectedPromo || showNoOffer"
        >No Offer</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
import { GiftIcon } from 'vue-feather-icons'
import CreatePromoModal from '@/components/Modals/CreatePromoModal'
import { createNamespacedHelpers } from 'vuex'
import { orderBy } from 'lodash-es'
const PromoModule = createNamespacedHelpers('promo')
const CampaignModule = createNamespacedHelpers('campaign')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const CustomQuestionModule = createNamespacedHelpers('customQuestion')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'PromoDropdownV2',
  props: {
    source: {
      type: String,
      default: '',
    },
    includeModal: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    includeUniquePromos: {
      type: Boolean,
      default: true,
    },
  },
  components: { CreatePromoModal, GiftIcon },
  data: () => ({
    loadModal: false,
    selectedPromo: undefined,
    showNoOffer: false,
  }),
  computed: {
    ...PromoModule.mapGetters(['promos', 'promo']),
    ...CampaignModule.mapGetters(['campaign']),
    ...ConversationModuleV2.mapGetters(['selectActiveConversation']),
    ...CustomQuestionModule.mapGetters(['settings']),
    ...CompanyModule.mapGetters(['selectIsPermitted']),
    filteredPromos() {
      let self = this
      const offers = this.promos
        .filter((promo) => {
          return self.selectActiveConversation
            ? promo.company == self.selectActiveConversation.company &&
                (!promo?.locations?.length ||
                  promo.locations.includes(self.selectActiveConversation?.location?._id))
            : true
        })
        .map((offer) => {
          return {
            ...offer,
            displayName: offer?.nickname || offer?.title,
          }
        })
      return orderBy(offers, ['displayName'], ['asc'])
    },
  },
  methods: {
    ...PromoModule.mapMutations(['resetPromo']),
    ...PromoModule.mapActions(['queryPromosWithSource']),
    addPromo() {
      if (this.source === 'customQuestion') {
        this.$router.push('/promos')
      } else {
        this.loadModal = true
        this.resetPromo()
        this.$root.$emit('add-promo')
        this.promo.source = this.source
        if (this.includeModal) this.$refs.createPromoModal.open()
      }
    },
    setPromo(promo) {
      this.selectedPromo = promo
      if (promo) {
        this.$emit('selected', promo)
      }
    },
    reset() {
      this.selectedPromo = undefined
      this.$emit('reset')
    },
    async addedPromo(promo) {
      this.promo.source = this.source
      this.queryPromosWithSource()
      this.setPromo(promo)
    },
    openPromoDropdown(promo = null) {
      if (promo) {
        this.setPromo(this.promos.find((p) => p._id === promo._id))
      } else {
        this.showNoOffer = true
        this.$refs.promoDropdown.show()
      }
    },
  },
  async mounted() {
    this.promo.source = this.source
    await this.queryPromosWithSource()

    if (this.campaign.promo && this.source !== 'customQuestion') {
      this.setPromo(this.promos.find((promo) => this.campaign.promo === promo._id))
    } else if (this.source === 'customQuestion' && this.settings.promo) {
      this.selectedPromo = this.promos.find((promo) => this.settings.promo._id === promo._id)
      this.$emit('updatePromo', this.selectedPromo)
    }

    this.$root.$on('added-promo', this.addedPromo)
  },
  destroyed() {
    this.resetPromo()
    this.$root.$off('added-promo', this.addedPromo)
  },
}
</script>

<style scoped lang="scss">
a {
  text-transform: capitalize;
}
.dropdown-item {
  font-size: 14px;
}
.icon-large {
  font-size: 1.4em;
}
.promo-span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.promo-span img {
  width: 18px;
  height: 18px;
}
.action-icon {
  color: #95aac9;
}
.action-icon:hover {
  color: #12263f !important;
}
::v-deep #promo-dropdown .btn:focus {
  box-shadow: none !important;
}
::v-deep #promo-dropdown .btn-primary:focus {
  box-shadow: none !important;
}
::v-deep #promo-dropdown .btn-sm {
  padding: 0 0 0 0 !important;
}
</style>
