import PromoService from '@/services/PromoService'
import moment from 'moment-timezone'

const defaultPromo = () => ({
  _id: undefined,
  title: '',
  description: '',
  companies: [],
  locations: [],
  nickname: undefined,
  inStorePromoCode: '',
  inStorePromoFile: '',
  offPremisePromoCode: '',
  offPremisePromoFile: '',
  offPremiseLocationUrl: '',
  expiresAt: undefined,
  expiresIn: undefined,
  source: '',
  termsAndConditions: undefined,
})

export default {
  namespaced: true,
  state: {
    promos: [],
    promo: defaultPromo(),
    sourceFilter: 'all',
    isLoadingPromos: false,
  },
  getters: {
    selectPromos: (state) => state.promos,
    selectPromo: (state) => state.promo,
    selectSourceFilter: (state) => state.sourceFilter,
    selectPromoName: (state) => (promoId) => {
      const promo = state.promos.find((p) => p._id === promoId)
      return promo ? promo.nickname || promo.title : ''
    },
    selectIsLoadingPromos: (state) => state.isLoadingPromos,
  },
  mutations: {
    SET_SOURCE_FILTER(state, filter) {
      state.sourceFilter = filter
    },
    SET_PROMOS(state, promos) {
      state.promos = promos
    },
    SET_PROMO(state, promo) {
      state.promo = promo
    },
    RESET_PROMO(state) {
      state.promo = { ...defaultPromo() }
    },
    SET_IS_LOADING_PROMOS(state, status) {
      state.isLoadingPromos = status
    },
  },
  actions: {
    async formatPayload({ rootGetters }, promo) {
      const activeCompany = rootGetters['company/selectActiveCompany']
      const organization = rootGetters['organization/selectOrganization']
      const payload = {
        ...promo,
        ...(activeCompany._id === 'ALL_COMPANIES' && organization
          ? { organization: organization._id }
          : {}),
      }
      payload.expiresAt = moment(promo.expiresAt).endOf('day').toISOString() || undefined
      payload.expiresIn = parseInt(promo.expiresIn) || undefined
      payload.companyIds = promo.companies.length
        ? promo.companies
        : rootGetters['company/selectRelevantCompanies'].map((c) => c._id)
      /*payload.locationIds = promo.locations.length
        ? promo.locations
        : rootGetters['location/selectLocationsByCompanies'](payload.companyIds).map((l) => l._id)*/

      payload.locationIds = promo.locations.length ? promo.locations : []

      // remove fields the backend doesn't want
      delete payload.companies
      delete payload.locations

      return payload
    },

    async fetchPromos({ rootGetters, commit }) {
      commit('SET_IS_LOADING_PROMOS', true)
      const companies = rootGetters['company/selectRelevantCompanies']
      const activeCompany = rootGetters['company/selectActiveCompany']
      const organization = rootGetters['organization/selectOrganization']
      const response = await PromoService.listPromos({
        filters: {
          companyIds: companies.map((c) => c._id),
          ...(activeCompany._id === 'ALL_COMPANIES' ? { organizationIds: [organization._id] } : {}),
        },
      })

      commit('SET_PROMOS', response.body.data.promos)
      commit('SET_IS_LOADING_PROMOS', false)
    },

    async createPromo({ dispatch }, { promo }) {
      const formattedPromo = await dispatch('formatPayload', { ...promo })
      return PromoService.createPromo({ promo: formattedPromo })
    },

    async fetchPromo({}) {
      // TODO: implement this when needed
      console.log('fetching single promo')
    },

    async updatePromo({ dispatch }, { promoId, promo }) {
      const formattedPromo = await dispatch('formatPayload', { ...promo })
      return PromoService.updatePromo({ promoId, promo: formattedPromo })
    },

    async deletePromo({}, promoId) {
      const response = await PromoService.deletePromo({ promoId })
    },

    async setPromo({ commit }, promo) {
      commit('SET_PROMO', promo)
    },

    async setSourceFilter({ commit }, filter) {
      commit('SET_SOURCE_FILTER', filter)
    },

    async resetPromo({ commit }) {
      commit('RESET_PROMO')
    },

    async setIsLoadingPromos({ commit }, status) {
      commit('SET_IS_LOADING_PROMOS', status)
    },
  },
}
